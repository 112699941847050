// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aday-aydinlatma-metni-js": () => import("./../../../src/pages/aday-aydinlatma-metni.js" /* webpackChunkName: "component---src-pages-aday-aydinlatma-metni-js" */),
  "component---src-pages-biz-kimiz-amacimiz-js": () => import("./../../../src/pages/biz-kimiz/amacimiz.js" /* webpackChunkName: "component---src-pages-biz-kimiz-amacimiz-js" */),
  "component---src-pages-biz-kimiz-degerlerimiz-js": () => import("./../../../src/pages/biz-kimiz/degerlerimiz.js" /* webpackChunkName: "component---src-pages-biz-kimiz-degerlerimiz-js" */),
  "component---src-pages-biz-kimiz-surdurulebilirlik-cevresel-etkiyi-azaltma-js": () => import("./../../../src/pages/biz-kimiz/surdurulebilirlik/cevresel-etkiyi-azaltma.js" /* webpackChunkName: "component---src-pages-biz-kimiz-surdurulebilirlik-cevresel-etkiyi-azaltma-js" */),
  "component---src-pages-biz-kimiz-surdurulebilirlik-dongusel-ekonomi-js": () => import("./../../../src/pages/biz-kimiz/surdurulebilirlik/dongusel-ekonomi.js" /* webpackChunkName: "component---src-pages-biz-kimiz-surdurulebilirlik-dongusel-ekonomi-js" */),
  "component---src-pages-biz-kimiz-surdurulebilirlik-index-js": () => import("./../../../src/pages/biz-kimiz/surdurulebilirlik/index.js" /* webpackChunkName: "component---src-pages-biz-kimiz-surdurulebilirlik-index-js" */),
  "component---src-pages-biz-kimiz-surdurulebilirlik-sosyal-sorumluluk-ve-farkindalik-js": () => import("./../../../src/pages/biz-kimiz/surdurulebilirlik/sosyal-sorumluluk-ve-farkindalik.js" /* webpackChunkName: "component---src-pages-biz-kimiz-surdurulebilirlik-sosyal-sorumluluk-ve-farkindalik-js" */),
  "component---src-pages-biz-kimiz-tarihcemiz-js": () => import("./../../../src/pages/biz-kimiz/tarihcemiz.js" /* webpackChunkName: "component---src-pages-biz-kimiz-tarihcemiz-js" */),
  "component---src-pages-biz-kimiz-vizyonumuz-js": () => import("./../../../src/pages/biz-kimiz/vizyonumuz.js" /* webpackChunkName: "component---src-pages-biz-kimiz-vizyonumuz-js" */),
  "component---src-pages-ilanlar-js": () => import("./../../../src/pages/ilanlar.js" /* webpackChunkName: "component---src-pages-ilanlar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kvkk-js": () => import("./../../../src/pages/kvkk.js" /* webpackChunkName: "component---src-pages-kvkk-js" */),
  "component---src-pages-nerelerdeyiz-js": () => import("./../../../src/pages/nerelerdeyiz.js" /* webpackChunkName: "component---src-pages-nerelerdeyiz-js" */),
  "component---src-pages-rollerimiz-js": () => import("./../../../src/pages/rollerimiz.js" /* webpackChunkName: "component---src-pages-rollerimiz-js" */),
  "component---src-pages-yan-haklarimiz-js": () => import("./../../../src/pages/yan-haklarimiz.js" /* webpackChunkName: "component---src-pages-yan-haklarimiz-js" */),
  "component---src-pages-yasal-bildirimler-js": () => import("./../../../src/pages/yasal-bildirimler.js" /* webpackChunkName: "component---src-pages-yasal-bildirimler-js" */),
  "component---src-templates-department-page-js": () => import("./../../../src/templates/department_page.js" /* webpackChunkName: "component---src-templates-department-page-js" */)
}

